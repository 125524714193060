//JAVASCRIPT REQUIRED FOR ENTIRE WEBSITE
let $form, $message, timeoutId;

function captchaSubmit(grecaptchaResponse) {
    console.log("20201123a")
    // Considering jQuery.validate is being used as the validation mechanism
    if ($form.valid()) {
        $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();

        jQuery.ajax({
            type: "POST",
            url: $form.attr("action"),
            data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse
        })
            .done(function (response) {
                // Display response message to the user | add success class to the message wrapper
                $message.slideUp(400, () => {
                    $message
                        .removeClass("error")
                        .addClass("success")
                        .html(response)
                        .slideDown();
                });
                // Hide the message after 7 seconds
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(function () {
                    $message.slideUp(400, () => {
                        $message
                            .html("")
                            .removeClass("success");
                    });
                }, 7000);
                // Resetting reCaptcha (v2) if available
                grecaptcha && grecaptcha.reset();
                // Uncomment for Submiting Google Analytics

                // if (response.toLowerCase().indexOf('thank') > -1) {
                //     gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                //         'event_category': 'Form',
                //         'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                //         'value': 1
                //     });
                // }
                // Reset the form input fields
                $form.trigger("reset");
            })
            .fail(function (error) {
                // Display error message to the user | add error class to the message wrapper
                console.log(error);
                $message.slideUp(400, () => {
                    $message
                        .removeClass("success")
                        .addClass("error-send-mail")
                        .text(response ? response : "Oops! An error occured")
                        .slideDown();
                });
            });
    } else {
        grecaptcha && grecaptcha.reset();
    }
}

jQuery(document).ready(function () {

    //remove animation after 10s
    setTimeout(function () {
        jQuery('.callout-wrapper').removeClass('blinkingAnimation');
    }, 10000);
    jQuery('.popupClosebtn').on('click', function () {
        jQuery('#aboutXtl').get(0).pause();
        jQuery('#aboutXtl').get(0).currentTime = 0;

    });
    jQuery('.popupClosebtn1').on('click', function () {
        jQuery('#aboutXtl1').get(0).pause();
        jQuery('#aboutXtl1').get(0).currentTime = 0;

    });
    jQuery('.videoImgLink').on('click', function () {
        jQuery('#aboutXtl').get(0).play();
        jQuery('#aboutXtl').get(0).currentTime = 0;

    });

    jQuery("select").change(function () {
        jQuery(this).css('color', '#000');
    });

    jQuery(document).on("submit", 'form', e => {
        e.preventDefault();
        $form = jQuery(e.target);
        $message = $form.find(".form-messages");
        grecaptcha.execute();
        return false;
    });


    jQuery('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        var $el = jQuery(this);
        $el.toggleClass('active-dropdown');
        var $parent = jQuery(this).offsetParent(".dropdown-menu");
        if (!jQuery(this).next().hasClass('show')) {
            jQuery(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = jQuery(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        jQuery(this).parent("li").toggleClass('show');

        jQuery(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            jQuery('.dropdown-menu .show').removeClass("show");
            $el.removeClass('active-dropdown');
        });

        if (!$parent.parent().hasClass('navbar-nav')) {
            // $el.next().css( { "top": $el[0].offsetTop, "left": $parent.outerWidth() - 4 } );
        }

        return false;
    });

    jQuery('.dropdown-menu.parent-menu').on('mouseleave', function (e) {
        if (window.innerWidth >= 1200) {
            jQuery('.dropdown-menu .show').removeClass("show");
            jQuery(this).removeClass('active-dropdown');

        }
    });



    jQuery(window).on('resize', _.throttle(function (e) {
        jQuery('.dropdown-menu.parent-menu').on('mouseleave', function () {
            if (window.innerWidth >= 1200) {
                jQuery('.dropdown-menu .show').removeClass("show");
                jQuery(this).removeClass('active-dropdown');
            }
        });
        if (window.innerWidth >= 1200) {
            jQuery('.nav-item.dropdown').on('mouseleave', function () {
                jQuery('.dropdown-menu.parent-menu').removeClass("show");
            });
        }
        jQuery('.nav-item.dropdown').removeClass('show');
        jQuery('.dropdown-menu.parent-menu').removeClass('show');
        jQuery('.dropdown-menu.parent-menu li').removeClass('show');
        jQuery('ul.dropdown-menu').removeClass('show');
    }, 50));

    jQuery('.navbar-nav.nav-secondary .nav-item .dropdown-menu li').on('mouseover', function () {
        jQuery(this).prev().addClass('noborderonhover');
        if (jQuery(this).prev().hasClass('collapse')) {

            jQuery(this).prev().prev().addClass('noborderonhover');
        }
    })
    jQuery('.navbar-nav.nav-secondary .nav-item .dropdown-menu li').on('mouseleave', function () {

        jQuery(this).prev().removeClass('noborderonhover');
        if (jQuery(this).prev().hasClass('collapse')) {

            jQuery(this).prev().prev().removeClass('noborderonhover');
        }
    })



    ///////////subpage calc height//////////////////
    if (jQuery('.subpage.subpageshort').length > 0) {
        var subpageContentHeight = jQuery('.subpage-content').height();
        // console.log(subpageContentHeight);
        var subpageContentTop = parseFloat(jQuery('.subpage-content').css('top'));
        // console.log(subpageContentTop);
        ////subpageContentHeight + subpageContentTop => sum up because top always minus/////////////
        jQuery('.subpage-content-wrapper').css('height', (subpageContentHeight + subpageContentTop) + 150 + 'px');
    }
    /////////////////////////////////////////////////////////////////////////    
    jQuery(window).on('resize', _.throttle(function (e) {
        // $(window).on('resize',function(e) {
        if (window.location.pathname.indexOf('index.php') > 0 || window.location.pathname.indexOf('.php') < 0) {
            return;
        }
        if (jQuery('.subpage.subpageshort').length > 0) {

            if (window.innerWidth >= 1200) {
                subpageContentHeight = jQuery('.subpage-content').height();
                subpageContentTop = parseFloat(jQuery('.subpage-content').css('top'));
                jQuery('.subpage-content-wrapper').css('height', (subpageContentHeight + subpageContentTop) + 150 + 'px');
            }
            else if (window.innerWidth < 1200) {
                // console.log('if 2')
                jQuery('.subpage-content-wrapper').css('height', 'auto');

            }
            return;
        }
    }, 50));


    ////////////////homepage line animation on scroll and sticky menu/////////////////////
    jQuery(document).scroll(function () {
        var $nav = jQuery(".sticky-menu");
        jQuery('.topbar-nav--desktop').toggleClass('scrolled', jQuery(this).scrollTop() > jQuery('.topbar-nav--desktop').height() + 230);
        $nav.toggleClass('scrolled', jQuery(this).scrollTop() > $nav.height() + 230);


        if (window.location.href.indexOf('index.php') >= 0 || window.location.pathname.indexOf('.php') < 0 && window.location.href.indexOf('portal') < 0) {

            /* Check the location of each desired element */
            var bottom_of_window = jQuery(window).scrollTop() + jQuery(window).height();
            var top_of_window = jQuery(window).scrollTop();
            // $('.overtheroad-boxes').each(function(i) {
            /////////////////////////box section//////////////////////////////////////////////////
            var bottom_of_boxes = jQuery('.overtheroad-boxes').offset().top + jQuery('.overtheroad-boxes').outerHeight();
            if (bottom_of_window > bottom_of_boxes) {
                jQuery('.overtheroad-boxes').addClass('fadein');
            }


            //////////////////////////////firstline-over the road////////////////////////
            var bottom_of_line = jQuery('.overtheroadLine').offset().top + jQuery('.overtheroadLine').outerHeight();

            if (bottom_of_window > jQuery('.overtheroadLine').offset().top) {
                // console.log('top',jQuery('.overtheroadLine').offset().top);
                //  jQuery('.overtheroadLine').css({'background' : '#fff'});

                jQuery('.lineWrapper').addClass('lineWrapper-visible');

            }

            //////////////////////////logistics white line//////////////////////            
            var bottom_of_boxes = jQuery('.logistics-line').offset().top + jQuery('.logistics-line').outerHeight();
            if (bottom_of_window > jQuery('.logistics-line').offset().top) {
                jQuery('.extend-line').addClass('visible');
            }

            //////////////////////////subscribe white line//////////////////////            
            var bottom_of_boxes = jQuery('.subscribe-whiteline-wrapper').offset().top + jQuery('.subscribe-whiteline-wrapper').outerHeight();
            if (bottom_of_window > jQuery('.subscribe-whiteline-wrapper').offset().top) {
                jQuery('.subscribe-whiteline-wrapper').addClass('subscribe-whiteline-visible');
            }

            /////////////////////////////////Dollar Animation////////////////////////


            var bottom_of_boxes = jQuery('.animation').offset().top + jQuery('.animation').outerHeight();
            if (bottom_of_window > jQuery('.animation').offset().top) {
                // console.log('hello');
                jQuery('#EdgeID').delay(1000).fadeIn();

            }
        }
    });
    if (!window.matchMedia)
    return;
    var current = jQuery('head > link[rel="icon"][media]');
    jQuery.each(current, function(i, icon) {
        var match = window.matchMedia(icon.media);
        function swap() {
            if (match.matches) {
                current.remove();
                current = jQuery(icon).appendTo('head');
            }
        }
        match.addListener(swap);
        swap();
    });
    var pathname = window.location.pathname; // Returns path only (/path/example.html)
    var data = pathname.split('/').slice(-2, -1)[0];
    console.log("data",data);
    if(data == "en"){
        jQuery(".content-right-bottom-award img").attr("src","../content/images/InsideLogistics_CarrierOfChoice_bw.png");
        jQuery(".content-right-bottom-award img").attr("alt","Inside Logistics Carrier of Choice logo");
    }else if(data == "fr"){
        jQuery(".content-right-bottom-award img").attr("src","../content/images/IL_transporteur_de_cix_logo_2021_French.png");
        jQuery(".content-right-bottom-award img").attr("alt","Inside Logistics Transporteur de Choix logo");
    }else{
        console.log("No data");
    }

});
function callVideoPopup(){
    console.log("ssswww");
    //jQuery('.videoImgLink').trigger('click');
    jQuery('#myModal-video1').modal('show');
    console.log(123);

    jQuery('#myModal-video1').on('shown.bs.modal', function () {
        jQuery('#aboutXtl1')[0].play();
      })
    //jQuery('#aboutXtl1').get(0).play();
    //jQuery('#aboutXtl1').get(0).currentTime = 0;
    //jQuery('.videoImgLink').trigger('click');
    //jQuery('#myModal-video1').modal('handleUpdate');
    /*jQuery('#myModal-video1').modal({
        show: true,
        focus: true
      })*/
}
